export default [
  {
    path: "/labRegister", // 入驻实验室
    name: "labRegister",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LabRegister/index.vue"),
  },

  {
    path: "/labList", // 实验室列表
    name: "labList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LabList/index.vue"),
  },
  {
    path: "/labDetail", // 实验室详情
    name: "labDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LabDetail/index.vue"),
  },
];
