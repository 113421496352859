<template>
  <div class="flex code">
    <el-input
      v-model="code"
      placeholder="请输入图形验证码"
      class="flex-1"
    ></el-input>
    <div class="code-img pointer" @click="getImgCode" v-loading="imgLoading">
      <img :src="verifyImage" />
    </div>
  </div>
</template>
<script>
import { fetchImgCode } from "@/api/login";
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      verifyImage: "",
      imgLoading: false,
    };
  },
  computed: {
    code: {
      get: function () {
        return this.value || "";
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.getImgCode();
  },
  methods: {
    async getImgCode() {
      this.imgLoading = true;
      const { verifyImage, uuid } = await this.$axios
        .get(fetchImgCode)
        .finally(() => {
          this.imgLoading = false;
        });
      this.verifyImage = verifyImage;
      this.$emit("setUuid", uuid);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.code {
  &-img {
    margin-left: 10px;
    height: 40px;
    width: 40%;
    > img {
      border-radius: $border-radius;
      height: 100%;
      width: 100%;
      position: relative;
      top: -1px;
    }
  }
}
</style>
