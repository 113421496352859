<template>
  <div class="top">
    <div class="top-inner flex flex-row flex-between">
      <div class="top-logoWrap">
        <img :src="$oss('home/logo.jpg')" class="top-logo" @click="goHome" />
      </div>
      <div class="flex flex-row top-right">
        <div class="search flex flex-row">
          <input
            class="search-input"
            placeholder="全站检索"
            v-model="wholeSearchWord"
          />
          <div class="search-btn pointer" @click="wholeSearchClick">
            搜&nbsp;&nbsp;索
          </div>
        </div>
        <div>
          <div class="flex logined" v-if="token">
            <Msg />
            <el-popover
              placement="top"
              width="170"
              v-model="popVisible"
              trigger="hover"
            >
              <div class="pop">
                <div @click="toUserCenter">用户中心</div>
                <div @click="toCart">购物车</div>
                <div @click="logout">退出登录</div>
              </div>
              <div class="auth flex flex-row" slot="reference">
                <div class="auth-icon">
                  <img src="@/assets/home/header/user.png" />
                </div>
                <div class="auth-name ellipsis">
                  {{ userBaseInfo.userRealName }}
                </div>
              </div>
            </el-popover>
          </div>
          <div class="loginEntry pointer" @click="toLogin" v-else>
            登录/注册
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="400px"
      class="dialog"
      :close-on-click-modal="false"
      @close="dialogOpenCallback"
    >
      <span slot="title"> </span>
      <Login
        v-if="dialogType == 'login'"
        @toRegister="registerClick"
        @forgetPwd="dialogType = 'forgetPwd'"
        @loginSuccess="loginSuccess"
      />
      <ForgetPwd
        v-if="dialogType == 'forgetPwd'"
        @setPwdSuccess="dialogType = 'login'"
      />
    </el-dialog>
  </div>
</template>
<script>
import { SET_SHOWLOGIN } from "@/store/types";
import { mapGetters } from "vuex";
import Login from "../Login/index";
import ForgetPwd from "./ForgetPwd";
import Msg from "./Msg.vue";
export default {
  name: "headBar",
  props: {
    searchKey: {
      type: String,
      default: "",
    },
  },
  components: {
    Login,
    ForgetPwd,
    Msg,
  },
  data() {
    return {
      popVisible: false,
      dialogVisible: false,
      dialogType: "login",
      showCart: false,
      afterLoginAction: {},
      wholeSearchWord: "",
    };
  },
  computed: {
    ...mapGetters(["token", "userBaseInfo", "showLogin"]),
  },
  watch: {
    showLogin(val) {
      if (val) {
        this.dialogType = "login";
        this.dialogVisible = true;
      } else {
        this.dialogType = "";
        this.dialogVisible = false;
      }
    },
  },
  created() {
    if (this.searchKey) {
      this.wholeSearchWord = this.searchKey;
    }
  },
  methods: {
    goHome() {
      const name = "home";
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
    toLogin() {
      this.$store.dispatch("login");
    },
    dialogOpenCallback() {
      if (this.dialogType) {
        this.dialogType = "";
        this.$store.commit(SET_SHOWLOGIN, false);
      }
    },
    registerClick() {
      this.dialogVisible = false;
      this.toRegister();
    },
    toRegister() {
      this.$jump.push({ name: "register" });
    },
    toCart() {
      this.popVisible = false;
      this.$router.push({ name: "goodsCart" });
    },
    toUserCenter() {
      this.popVisible = false;
      this.$router.push({ name: "userCenter" });
    },
    loginSuccess(token) {
      this.dialogVisible = false;
      this.$store.dispatch("LoginSuccess", token);
      this.$emit("loginSuccess");
      const { toPathName } = this.afterLoginAction;
      if (toPathName) {
        this.$router.push({ name: toPathName });
      }
    },
    // 退出登录
    logout() {
      this.popVisible = false;
      this.$store.dispatch("Logout");
    },
    wholeSearchClick() {
      if (!this.wholeSearchWord) {
        this.$message.warning("请输入要搜索的关键字");
        return;
      }
      const currentName = this.$route.name;
      const searchPath = "wholeSearch";
      if (currentName !== searchPath) {
        this.$router.push({
          name: searchPath,
          query: { key: encodeURIComponent(this.wholeSearchWord) },
        });
      } else {
        this.$emit("update:searchKey", this.wholeSearchWord);
        this.$emit("wholeSearch");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "@/style/common";
.top {
  height: 120px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-right {
    padding-top: 37px;
  }
  &-logoWrap {
    padding-top: 25px;
  }
  &-logo {
    width: 624px;
    height: 64px;
    cursor: pointer;
    // position: relative;
    // bottom: 8px;
  }
}
.logined {
  height: 44px;
  line-height: 44px;
}
.auth {
  &-icon {
    cursor: pointer;
    color: #1677ff;
    background: #deecff;
    border-radius: 50%;
    margin-right: 10px;
    padding: 0px 10px;
    img {
      width: 24px;
    }
  }
  &-name {
    max-width: 50px;
  }
}
.pop {
  padding: 0px 10px;
  > div {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    &:hover {
      color: #1677ff;
    }
  }
}
.search {
  margin-right: 27px;
  &-input {
    width: 230px;
    height: 40px;
    line-height: 40px;
    background: #ffffff;
    border: 1px solid $theme-color;
    padding: 0 15px;
  }
  &-btn {
    width: 111px;
    height: 40px;
    line-height: 40px;
    background: $theme-color;
    color: white;
    font-size: 18px;
    text-align: center;
  }
}
.loginEntry {
  width: 124px;
  height: 42px;
  line-height: 42px;
  background: #e3ecff;
  color: #4483ff;
  text-align: center;
}
</style>
