export default [
  {
    path: "/discountActs", // 优惠活动列表
    name: "discountActs",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Activity/ActsList/index.vue"
      ),
  },
  {
    path: "/discountActDetail", // 优惠活动详情
    name: "discountActDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Activity/ActsDetail/index.vue"
      ),
  },
];
