export default [
  {
    path: "/allianceList", // 联盟专区列表页
    name: "allianceList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllianceZone/AllianceList.vue"
      ),
  },
  {
    path: "/allianceDetail", // 联盟详情
    name: "allianceDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllianceZone/AllianceDetail/index.vue"
      ),
  },
  {
    path: "/allianceActDetail", // 联盟活动详情
    name: "allianceActDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllianceZone/AllianceActDetail/index.vue"
      ),
  },
  {
    path: "/joinAlliance", // 加入联盟
    name: "joinAlliance",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllianceZone/JoinAlliance/index.vue"
      ),
  },
];
