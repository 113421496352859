export default [
  {
    path: "/expertsList", // 专家库
    name: "expertsList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Experts/ExpertsList/index.vue"
      ),
  },
  {
    path: "/expertsDetail", // 专家详情
    name: "expertsDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Experts/ExpertsDetail/index.vue"
      ),
  },
];
