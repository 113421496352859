export const DEED_ORDER_SUCCESS = "200"; // 下单完成
export const DEED_ORDER_WAITSAMPLE = "300"; // 等待样品
export const DEED_ORDER_CHECKING = "400"; // 正在检测
export const DEED_ORDER_REPORT = "500"; // 报告编制
export const DEED_ORDER_FINISH = "600"; // 完成检测
// 严选商城订单状态
export const DEED_ORDER_STATUS = [
  { id: 200, name: "下单完成" },
  { id: 300, name: "等待样品" },
  { id: 400, name: "正在检测" },
  { id: 500, name: "报告编制" },
  { id: 600, name: "完成订单" },
];

// 严选商城服务支付方式
export const PAYTYPE_NO = "0"; // 未选择支付方式
export const PAYTYPE_XY = "1"; // 协议支付
export const PAYTYPE_WX = "2"; // 微信支付

// 严选商城服务支付状态
export const PAYSTATUS_NO = "0"; // 未支付
export const PAYSTATUS_SUCCESS = "1"; // 支付成功
export const PAYSTATUS_FAIL = "2"; // 支付失败

// 手机验证码类型 1-用户注册；2-用户登录；3-找回密码；4-修改手机号码
export const SYSCODE_TYPE = {
  register: "1",
  login: "2",
  setPwd: "3",
  updatePhone: "4",
};
