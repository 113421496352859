<template>
  <el-form :model="form" class="acc">
    <el-form-item label="">
      <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
    </el-form-item>
    <el-form-item label="">
      <el-input
        v-model="form.password"
        type="password"
        placeholder="请输入密码"
      ></el-input>
    </el-form-item>
    <el-form-item label="">
      <ImgCode v-model="form.code" @setUuid="setLoginUuid" ref="imgCode" />
    </el-form-item>
    <div>
      <el-button
        type="primary"
        class="acc-btn"
        @click="toLogin"
        :loading="loginBtnLoading"
        >立即登录</el-button
      >
    </div>
  </el-form>
</template>
<script>
import { login } from "@/api/login";
import { Base64 } from "js-base64";
import ImgCode from "@/components/ImgCode/index";
export default {
  components: {
    ImgCode,
  },
  data() {
    return {
      form: { username: "", password: "", code: "", uuid: null },
      loginBtnLoading: false,
    };
  },
  methods: {
    // 点击登录按钮
    async toLogin() {
      const { username, password, code, uuid } = this.form;
      if (username && password && code) {
        this.loginBtnLoading = true;
        const params = {
          username: Base64.toBase64(username),
          password: Base64.toBase64(password),
          code,
          uuid,
        };
        try {
          const token = await this.$axios.post(login, params).finally(() => {
            this.loginBtnLoading = false;
          });
          this.$emit("loginSuccess", token);
        } catch (e) {
          this.$refs.imgCode.getImgCode();
        }
      }
    },
    setLoginUuid(data) {
      this.form.uuid = data;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.acc {
  &-btn {
    width: 100%;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 12px 0;
    letter-spacing: 2px;
    font-size: 16px;
  }
}
</style>
