<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// 富文本样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { platformBaseInfo, platformNav } from "@/api/home"; //
import {
  SET_PLATFORM_BASE,
  SET_PLATFORM_BASENAV,
  SET_PLATFORM_MORENAV,
} from "@/store/types";
export default {
  data() {
    return {};
  },
  created() {
    if (this.$store.getters.token) {
      this.$store.dispatch("GetUserBaseInfo");
      this.$store.dispatch("GetUserMsgUnreadCount");
    }
    this.getPlatformBaseInfo();
    this.getNavs();
  },
  methods: {
    async getPlatformBaseInfo() {
      const res = await this.$axios.get(platformBaseInfo);
      this.$store.commit(SET_PLATFORM_BASE, res);
    },
    async getNavs() {
      const res = await this.$axios.get(platformNav);
      const max = 8;
      const baseItem = [];
      const moreItem = { bigMenu: [], smallMenu: [] };
      res.forEach((item) => {
        if (+item.orderNum <= max) {
          baseItem.push(item);
        } else {
          if (item.childBars && item.childBars.length) {
            moreItem.bigMenu.push(item);
          } else {
            moreItem.smallMenu.push(item);
          }
        }
      });
      this.$store.commit(SET_PLATFORM_BASENAV, baseItem);
      this.$store.commit(SET_PLATFORM_MORENAV, moreItem);
    },
  },
};
</script>

<style lang="scss">
@import "./style/flex";
@import "./style/common";
#app {
  font-family: "Microsoft YaHei", "微软雅黑", Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}
html {
  font-size: 14px;
}
body {
  min-width: 1220px;
}
::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 6px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 12px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #dadae4;
}

::-webkit-scrollbar-thumb:active {
  background-color: #77797a;
}
</style>
<style lang="scss" scoped>
.consult {
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 1;
  background: black;
  color: white;
  padding: 10px;
  cursor: pointer;
}
</style>
