<template>
  <div class="progress flex flex-center">
    <div class="progress-inner flex flex-center">
      <div class="progress-step">
        <el-steps :active="step" finish-status="success" align-center>
          <el-step
            v-for="(step, index) in steps"
            :key="index"
            :title="step"
          ></el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "progressBar",
  props: {
    step: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      steps: ["1.我的购物车", "2.确认订单信息", "3.在线支付订单"],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.progress {
  // margin-bottom: 30px;
  &-inner {
    width: $max-width;
    padding: 30px 0;
  }
  &-step {
    width: 600px;
    padding-top: 15px;
  }
}
.logo {
  background: #ddd;
  width: 200px;
  height: 80px;
}
</style>
