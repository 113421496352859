<template>
  <el-form :model="form" class="acc">
    <el-form-item label="">
      <el-input
        v-model="form.userPhone"
        placeholder="请输入手机号码"
      ></el-input>
    </el-form-item>
    <el-form-item label="">
      <PhoneCode
        v-model="form.smsCode"
        :phone="form.userPhone"
        :codeType="loginCodeType"
      />
    </el-form-item>
    <div>
      <el-button
        type="primary"
        class="acc-btn"
        @click="toLogin"
        :loading="loginBtnLoading"
        >立即登录</el-button
      >
    </div>
  </el-form>
</template>
<script>
import { phoneLogin } from "@/api/login";
import PhoneCode from "@/components/PhoneCode/index";
import { SYSCODE_TYPE } from "@/constant/index";
export default {
  components: {
    PhoneCode,
  },
  data() {
    return {
      form: { userPhone: "", smsCode: "" },
      loginBtnLoading: false,
      loginCodeType: SYSCODE_TYPE.login,
    };
  },
  created() {},
  methods: {
    // 点击登录按钮
    async toLogin() {
      const { userPhone, smsCode } = this.form;
      if (userPhone && smsCode) {
        this.loginBtnLoading = true;
        const params = {
          userPhone,
          smsCode,
        };
        const token = await this.$axios.post(phoneLogin, params).finally(() => {
          this.loginBtnLoading = false;
        });
        this.$emit("loginSuccess", token);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.acc {
  &-btn {
    width: 100%;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 12px 0;
    letter-spacing: 2px;
    font-size: 16px;
  }
}
</style>
