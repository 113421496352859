<template>
  <el-badge :value="msgUnread || ''" class="msg">
    <i class="el-icon-message-solid msg-icon" @click="toLookMsg"></i>
  </el-badge>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["msgUnread"]),
  },
  methods: {
    toLookMsg() {
      this.$router.push({ name: "myMessage" });
    },
  },
};
</script>
<style lang="scss" scoped>
.msg {
  margin-right: 15px;
  /deep/ .el-badge__content {
    right: 16px;
  }
  &-icon {
    cursor: pointer;
    color: #1677ff;
    font-size: 1.8rem;
    background: #deecff;
    padding: 9px;
    border-radius: 50%;
  }
}
</style>
