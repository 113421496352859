const Oss = function (url, catalogue) {
  const com = `https://store-img.cre-tep.com/frontDeskProject/`;
  return `${com}${catalogue || "images"}/${url}`;
};
export default {
  install(Vue) {
    Object.assign(Vue.prototype, {
      $oss: Oss,
    });
  },
};
