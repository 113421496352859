// 首页
export const friendlyLink = `/api/unoauth/homepage/selectPlatformLinksList`; // 友情链接
export const platformBaseInfo = `/api/unoauth/homepage/selectPlatformBaseinfo`; // 获取平台基础配置信息
export const platformNav = `/api/unoauth/homepage/selectPlatformNavigationBarList`; // 获取导航栏列表数据
export const homeModulePos = `/api/unoauth/homepage/selectPlatformHomeLayoutList`; // 首页布局模块
export const homeBanner = `/api/unoauth/homepage/selectPlatformBannerList`; // banner
export const dynamicAd = `/api/unoauth/homepage/selectPlatformAdvertisementList`; // 动态广告
export const hotLab = `/api/unoauth/homepage/selectLaboratoryPageList`; // 热门实验室

export const settledNewEnt = `/api/unoauth/homepage/getNewSettledEnterprise`; // 获取最新入驻企业列表
export const settledNewLab = `/api/unoauth/homepage/getNewSettledLaboratory`; // 获取最新入驻实验室列表
export const superProductList = `/api/unoauth/homepage/getQualityGoodsListForHomepage`; // 优质产品展示
export const newsBanner = `/api/unoauth/homepage/getNewsImageList`; // 新闻图片轮播

// 首页数据展示模块
// 获取地图省、市级别实验室地理位置，设备个数统计
export const mapData = `/api/unoauth/homepage/getProvinceAndCityLabDeviceCount`;
// 首页地图大屏-获取地图设备数量前5的省份
export const deviceTop5 = `/api/unoauth/homepage/getTop5DeviceProvinces`;
// 首页地图大屏-获取总设备数以及检测能力数
export const deviceAndAbilityCount = `/api/unoauth/homepage/getTotalCount`;
// 首页地图大屏-获取检测能力父类别及子类别数量`
export const abilitySort = `/api/unoauth/homepage/selectLaboratoryAbilityTypeCount`;

// 精选实验室
export const carefullyChosenLabs = `/api/unoauth/checkmallbusiness/getRecommendCheckmallBusiness`;
