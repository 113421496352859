// 用户中心-账号信息
export const isJoinLab = `/api/oauth/relation/selectLaboratoryUserRelation`; //获取实验室用户关联信息`
export const toJoinLab = `/api/oauth/relation/insertLaboratoryUserRelation`; // 加入实验室
export const judgeUserRole = `/api/oauth/user/getUserCapacityRespond`;
// 用户中心-用户收货地址管理
export const addressList = `/api/oauth/address/listPlatformUserAddress`;
export const addressDetail = `/api/oauth/address/getPlatformUserAddressDetail`;

//
export const saveAddress = `/api/oauth/address/platformUserAddressEdit`;
export const delAddress = `/api/oauth/address/platformUserAddressRemove`;

// 用户中心-我的需求
export const userDemand = `/api/oauth/customer/getUserDemandList`; // 获取用户需求
export const editDemand = `/api/oauth/customer/editDemand`; // 修改用户需求

// 用户中心-我的消息
export const myMessages = `/api/oauth/notice/listForUser`; // 我的消息列表
export const getUserMsgUnreadCount = `/api/oauth/notice/getUnreadCount`; // 消息未读数
export const setMsgRead = `/api/oauth/notice/getInfoByIdForUser`; // 获取消息详情，同时修改状态

//用户中心-参与的培训
export const myTrainClass = `/api/oauth/course/pageRareCourseOrder`;

// 用户中心-严选商城委托单
export const deedList = `/api/oauth/checkmallorder/getUserOrderList`;
export const deedDetail = `/api/oauth/checkmallorder/getOrderUserCentreDetailed`;
export const discardDeedById = `/api/oauth/checkmallorder/abandonOrderUserCentre`; // 废弃订单
export const deedTotalMoney = `/api/oauth/checkmallorder/sumPriceByCheckmallBusinessId`; // 总金额
export const toAskFor = `/api/oauth/orderapplyappeal/insertOrderApplyAppeal`; // 申请申述
export const askForResult = `/api/oauth/orderapplyappeal/selectOrderApplyAppeal`; // 申请申述结果
export const saveReport = `/api/oauth/checkmallorder/insertRareCheckmallOrderReport`; // 上传检测报告

// 用户中心-优选商城订单
export const userGoodOrderList = `/api/oauth/productmall/order/selectUserCenterProductmallOrderList`;
export const userGoodOrderDetail = `/api/oauth/productmall/order/getUserCenterProductmallOrderDetail`; // 查询产品商城订单详情
export const userCancelOrder = `/api/oauth/productmall/order/cancelProductmallOrder`; // 取消订单

// 商城管理-严选商城服务管理
export const scrictDeviceList = `/api/oauth/checkmall/getUserDeviceProductList`; // 列表
export const delService = `/api/oauth/checkmall/deleteCheckmallWares`; // 删除服务
export const editService = `/api/oauth/checkmall/updataCheckmallWares`; // 修改服务
export const addService = `/api/oauth/checkmall/insertCheckmallWares`; // 新增服务
export const serveceChangeStatus = `/api/oauth/checkmall/updataCheckmallWaresState`; // 更改检测商品状态
export const getBusinessCustomerServer = `/api/unoauth/customer/server/getBusinessCustomerServer`; // 获取商城客服人员
export const setBusinessCustomerServer = `/api/oauth/customer/server/setBusinessCustomerServer`; // 设置商城客服人员
export const userIsCustomer = `/api/oauth/customer/server/getCustomerServerAuthority`; // 判断用户是否为客服

// 商城管理-优选商城商品管理
export const getGoodCate = `/api/unoauth/productmall/selectProductmallCategoryList`; //获取产品商城产品分类列表
export const getAttribute = `/api/unoauth/productmall/selectPMServiceCategoryAttributes`; // 根据产品分类ID获取属性列表
export const addGoodInfo = `/api/oauth/productmall/insertProductmallWares`; // 新增产品商城产品
export const updateGoodInfo = `/api/oauth/productmall/updateProductmallWares`; // 上下架
export const delGood = `/api/oauth/productmall/deleteProductmallWares`; // 删除商品
export const changeGoodStatus = `/api/oauth/productmall/updateProductmallWaresStatus`; // 上下架
export const getMallBusinessInfo = `/api/oauth/productmallbusiness/getProductBusinessInfo`; // 获取产品（优选）商城商家信息
export const goodsList = `/api/oauth/productmall/selectProductmallList`; // 商品列表
export const goodsDetail = `/api/unoauth/productmall/getProductmallWaresDetail`; // 商品详情

// 商城管理-商品订单管理
export const mallGoodOrderList = `/api/oauth/productmall/order/selectBusinessCenterProductmallOrderList`;
export const mallGoodOrderDetail = `/api/oauth/productmall/order/getBusinessCenterProductmallOrderDetail`;
// 获取商城订单获取快递详细信息
export const getOrderKuaidiData = `/api/oauth/kuaidi/getMOrderKuaidiData`;
// 查询快递100快递公司及编号规则列表
export const getKuaidiList = `/api/unoauth/kuaidi/getKuaidiCodeList`;
// 优选（产品）商城订单新增、修改快递信息
export const goodAddKuaidiInfo = `/api/oauth/kuaidi/savePMKuaidiInfo`;
// 严选（检测）商城订单新增、修改快递信息
export const serviceAddKuaidiInfo = `/api/oauth/kuaidi/saveCMKuaidiInfo`;

// 实验室管理-实验室能力维护
export const addOrEditAblity = `/api/oauth/laboratory/laboratoryinsertOrUpdataLaboratoryAbility`; // 新增或修改能力
export const delAbility = `/api/oauth/laboratory/deleteLaboratoryAbility`; // 删除检测能力

// 实验室管理-实验室信息管理
export const getUserLabInfo = `/api/oauth/laboratory/getLaboratory`; // 获取用户所属实验室信息
export const joinOrUpdateSuperMall = `/api/oauth/productmallbusiness/insertOrUpdateProductmallBusiness`; // 入驻/更新产品商城;
export const joinOrUpdateStrictMall = `/api/oauth/checkmallbusiness/setOrUpdataCheckmallOrder`; // 入驻/更新检测商城
export const getStrictMallDetail = `/api/oauth/checkmallbusiness/getCheckmallBusinessInfo`; // 获取服务（严选）商城商家信息

// 实验室管理-实验室设备管理
export const saveDevice = `/api/oauth/laboratory/equipment/addOrUpdate`; // 保存设备
export const deviceList = `/api/oauth/laboratory/equipment/listForLaboratory`; // 设备列表
export const delDevice = `/api/oauth/laboratory/equipment/delete`; // 设备删除
export const deviceDetail = `/api/unoauth/laboratory/equipment/getInfoById`; // 设备详情
// 权限
export const getMemberAuth = `/api/oauth/jurisdiction/selectUserJurisdiction`;
export const setUserAuth = `/api/oauth/jurisdiction/insertUserJurisdiction`;

// 订单/商品评价API
export const getOrderEvaluate = `/api/oauth/evaluate/getOrderInfoEvaluateSelf`; // 获取我对当前订单的评论
export const addOrEditEvaluate = `/api/oauth/evaluate/addOrEditEvaluate`; // 提交/修改订单评价
