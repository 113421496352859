export default [
  {
    path: "/userCenter/applyEstimate",
    name: "applyEstimate", // 专家评估记录
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ExpertsCenter/applyEstimate/index.vue"
      ),
  },
  {
    path: "/userCenter/expertRegister",
    name: "expertRegister", // 申请入驻为专家
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Experts/ExpertRegister/index.vue"
      ),
  },
];
