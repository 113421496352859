<template>
  <div class="wrapper">
    <div class="footer flex flex-center">
      <div>
        <div class="footer-inner flex flex-row">
          <div class="com block">
            <div class="com-logo">
              <img :src="$oss('home/logo-white.png')" />
            </div>
            <div class="com-list">
              <div>{{ platform.platformSoft }}</div>
              <div>{{ platform.platformIcpRecord }}</div>
              <div>
                <img src="@/assets/home/footer/office.png" class="com-office" />
                {{ platform.platformPnsRecord }}
              </div>
            </div>
          </div>
          <div class="us block">
            <div class="us-title pointer" @click="contactUsClick">
              联系我们 >
            </div>
            <div class="us-list">
              <div>{{ platform.platformLinkAddress }}</div>
              <div>联系电话：{{ platform.platformLinkPhone }}</div>
              <div>邮编：{{ platform.platformPostalCode }}</div>
              <div>邮箱：{{ platform.platformEmail }}</div>
            </div>
          </div>
          <div class="us block">
            <div class="us-title">技术支持</div>
            <div class="us-list">
              <!-- <div>技术热线：0731-84118699</div> -->
              <div>{{ platform.platformServiceTime }}</div>
              <div>非工作时间可邮箱留言</div>
              <div>我们将在第一时间与您联系</div>
            </div>
          </div>
          <div class="us block">
            <div class="us-title">其他</div>
            <div class="us-list">
              <div class="flex">
                <div class="us-split">
                  <a href="/baseIntroduction" target="blank">关于我们</a>
                </div>
                <div>
                  <a
                    :href="$oss('平台端使用手册.pdf', 'protocols')"
                    target="blank"
                    >使用手册</a
                  >
                </div>
              </div>
              <div class="flex">
                <div class="us-split">
                  <a :href="$oss('用户协议.html', 'protocols')" target="blank"
                    >用户协议</a
                  >
                </div>
                <div>
                  <a :href="$oss('免责条款.html', 'protocols')" target="blank"
                    >免责条款</a
                  >
                </div>
              </div>
              <div class="flex">
                <!-- <div class="us-split"><label>快递查询</label></div> -->
                <!-- <div><label>关于我们</label></div> -->
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="embed">
              <embed
                src="https://program.xinchacha.com/web/1541709652201250816=www.cre-tep.com.svg"
                width="103"
                height="38"
                type="image/svg+xml"
                pluginspage="//www.adobe.com/svg/viewer/install/"
              />
              <div class="embed-mask pointer" @click="gonganClick"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["platform"]),
  },
  methods: {
    contactUsClick() {
      this.$jump.push({ name: "contactUs" });
    },
    aboutUsClick() {
      this.$jump.push({ name: "baseIntroduction" });
    },
    gonganClick() {
      window.open(
        "https://xyt.xcc.cn/getpcInfo?sn=1541709652201250816&language=CN&certType=8&url=www.cre-tep.com"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.wrapper {
  background: white;
  padding-top: 50px;
}
.footer {
  box-sizing: border-box;
  background: #2b2b2b;
  padding: 42px 0 20px 0;
  &-inner {
    width: $max-width;
    > div.block {
      margin-right: 60px;
    }
  }
}
.us {
  &-title {
    color: white;
    margin-bottom: 20px;
  }
  &-list {
    font-size: 0.85rem;
    color: #b1b1b1;
    > div {
      margin-bottom: 20px;
      a {
        cursor: pointer;
        text-decoration: none;
        color: #b1b1b1;
        &:hover {
          color: #9a9a9a;
        }
      }
    }
  }
  &-split {
    min-width: 90px;
    display: inline-block;
  }
}
.com {
  &-logo {
    height: 46px;
    width: 209px;
    margin-bottom: 28px;
    > img {
      width: 100%;
      height: 100%;
    } // background: white;
  }
  &-list {
    font-size: 0.85rem;
    color: #b1b1b1;
    > div {
      margin-bottom: 20px;
    }
  }
  &-office {
    width: 18px;
    position: relative;
    top: -1px;
  }
}
.embed {
  display: inline-block;
  position: relative;
  &-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 103px;
    height: 38px;
    z-index: 2;
  }
}
</style>
