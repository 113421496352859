const Jump = {
  push({ name, query }) {
    const origin = location.origin;
    let queryStr = "";
    if (query) {
      queryStr = Object.keys(query).reduce((pre, cur) => {
        return `${pre}${pre ? "&" : "?"}${cur}=${query[cur]}`;
      }, "");
    }
    window.open(`${origin}/${name}${queryStr}`);
  },
};
export default {
  install(Vue) {
    Object.assign(Vue.prototype, {
      $jump: Jump,
    });
  },
};
