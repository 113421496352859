export default [
  {
    path: "/userCenter/enterpriseInfo", // 企业信息管理
    name: "enterpriseInfo",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EntManage/EnterpriseInfo/index.vue"
      ),
  },
  {
    path: "/userCenter/enterpriseAccount", // 企业账号管理
    name: "enterpriseAccount",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EntManage/EnterpriseAccount/index.vue"
      ),
  },
];
