import MallManage from "./userCenterMallManage";
import UserCenter from "./userCenterUser";
import LabManage from "./userCenterLabManage";
import ExpertCenter from "./userCenterExptert";
import Enterprise from "./userCenterEntManage";
export default [
  {
    path: "/userCenter", // 用户中心
    name: "userCenter",
    redirect: "/userCenter/account",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserCenter/index.vue"),
    children: [
      ...UserCenter,
      ...MallManage,
      ...LabManage,
      ...ExpertCenter,
      ...Enterprise,
    ],
  },
];
