<template>
  <div class="nav">
    <div class="nav-inner flex flex-row flex-between">
      <div
        v-for="(item, index) in pageBaseNavs"
        :key="index"
        class="nav-item pointer flex-1"
        :class="{ acitve: activeId === item.navigationBarId }"
        @click="navClick(item)"
      >
        <el-dropdown
          v-if="item.childBars && item.childBars.length"
          class="nav-dropdown"
          placement="bottom"
          @command="handleCommand"
        >
          <span class="el-dropdown-link nav-title">
            {{ item.navigationBarName }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(child, cIndex) in item.childBars"
              :key="`c${cIndex}`"
              :command="{ ...child, pId: item.navigationBarId }"
            >
              <div class="nav-childName">{{ child.navigationBarName }}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span v-else> {{ item.navigationBarName }}</span>
      </div>
      <div class="nav-item">
        <el-popover placement="bottom" trigger="hover">
          <div class="expend">
            <div
              v-for="(expendBig, bIndex) in pageMoreNavs.bigMenu"
              :key="`b${bIndex}`"
              class="expend-item"
            >
              <div class="expend-bigTitle">
                {{ expendBig.navigationBarName }}
              </div>
              <div>
                <span
                  v-for="(bigChild, bcIndex) in expendBig.childBars"
                  :key="`bc${bcIndex}`"
                  class="expend-bigChildName"
                  :class="{ active: activeId === bigChild.navigationBarId }"
                  @click="expendMenuClick(bigChild)"
                  >{{ bigChild.navigationBarName }}</span
                >
              </div>
            </div>
            <div class="expend-item">
              <span
                v-for="(expendSmall, sIndex) in pageMoreNavs.smallMenu"
                :key="`s${sIndex}`"
                class="expend-bigChildName"
                :class="{ active: activeId === expendSmall.navigationBarId }"
                @click="expendMenuClick(expendSmall)"
              >
                {{ expendSmall.navigationBarName }}
              </span>
            </div>
          </div>
          <div slot="reference">
            <i class="el-icon-s-unfold pointer"></i>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_NAV } from "@/store/types";

export default {
  name: "headNav",
  data() {
    return {
      activeId: null,
    };
  },
  computed: {
    ...mapGetters(["nav", "pageBaseNavs", "pageMoreNavs"]),
  },
  created() {
    this.initNav();
  },
  mounted() {
    this.activeId = this.nav || null;
  },
  methods: {
    // 初始化菜单
    initNav() {
      const currentRname = this.$route.name;
      const navIndex = this.pageBaseNavs.findIndex(
        ({ linkPath }) => linkPath && linkPath === currentRname
      );
      if (navIndex > -1) {
        this.$store.commit(
          SET_NAV,
          this.pageBaseNavs[navIndex].navigationBarId
        );
      }
    },
    // 导航菜单点击
    navClick(item) {
      // 外链
      if (item.isFrame === "0") {
        window.open(item.linkPath);
      } else if (item.linkPath && item.linkPath !== "/") {
        this.activeId = item.navigationBarId;
        this.$store.commit(SET_NAV, this.activeId);
        this.jump(item);
      }
    },
    // 导航菜单下拉菜单点击
    handleCommand({ pId, ...rest }) {
      if (rest.isFrame === "0") {
        window.open(rest.linkPath);
      } else {
        this.activeId = pId;
        this.$store.commit(SET_NAV, pId);
        this.jump({ ...rest });
      }
    },
    // 扩展菜单点击
    expendMenuClick(item) {
      if (item.isFrame === "0") {
        window.open(item.linkPath);
      } else {
        this.activeId = item.navigationBarId;
        this.$store.commit(SET_NAV, this.activeId);
        this.jump(item);
      }
    },
    // 跳转页面
    jump(item) {
      const { linkPath, isBlank } = item;
      // 当前页面不跳转
      if (linkPath.includes(this.$route.name)) return;
      // 打开新页面
      if (isBlank === "1") {
        let jumpName = linkPath;
        if (linkPath === "home") jumpName = "";
        this.$jump.push({ name: jumpName });
        return;
      }
      // 单页跳转
      const route = this.$router.matcher.match(linkPath);
      if (route) {
        let routerName = route.name;
        if (!routerName) routerName = "home";
        this.$router.push({ name: routerName, query: route.query });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.nav {
  height: 50px;
  line-height: 50px;
  background: $theme-color;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-item {
    font-size: 16px;
    color: white;
    min-width: 100px;
    text-align: center;
    height: 50px;
    &:hover,
    &.acitve {
      background: $theme-color-hard;
    }
  }
  &-dropdown {
    width: 100%;
  }
  &-childName {
    min-width: 100px;
    text-align: center;
  }
  &-title {
    font-size: 16px;
    color: white;
  }
}
.el-icon-s-unfold {
  font-size: 18px;
  color: white;
}
.el-dropdown-link {
  display: inline-block;
  width: 100%;
}
.expend {
  padding: 25px 10px 25px 15px;
  &-item {
    border-bottom: 1px dashed #dbdbdb;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &-bigTitle {
    font-size: 22px;
    color: #333333;
    margin-bottom: 18px;
  }
  &-bigChildName {
    font-size: 18px;
    color: #787d7b;
    margin-right: 24px;
    cursor: pointer;
    &:hover {
      color: #4483ff;
    }
    &.active {
      color: #4483ff;
    }
  }
}
</style>
